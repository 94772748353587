import React from 'react';
import Page from './../components/page'
import SEO from './../components/seo'
import Resume from './../assets/pdf/AlexFitch_Resume.pdf';

const ResumePage = () => (
  <Page className='resume-page'>
      <SEO
          title='Alex Fitch'
          description='Designer & developer.'
          keywords={[`Alex Fitch resume`, `designer nyc`, `web developer nyc`]}
      />

      <object data={Resume} className='resume' aria-label='resume' />
  </Page>
)

export default ResumePage
